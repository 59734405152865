import PagePost from '../../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';

import { home as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import {
    Hero,
    SEO
} from '@brandedholdings/react-components';

class PlaceHolder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {variant: 'default'};
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    static propTypes = {
        ppc: PropTypes.bool.isRequired,
        hero: PropTypes.node.isRequired
    };

    static defaultProps = {
        ppc: true,
        hero: (<Hero
                heading="We've found these&nbsp;offers"
                subheading="Custom tailored to your needs"
                actionText={ null }
                actionLink="#"
                heroModifiers="hero--arrow"
                actionModifiers={ null }
        />)
    };

    render() {
        const page = this.props;

        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        var transferCount = 'Not set';
        if (typeof window !== 'undefined') {
            transferCount = window.localStorage.getItem('transferCount');
        }

        return (
            <PageWrapper navigation={true}>
                <SEO post={ post } />
                <Hero
                    heading="Great News!"
                    subheading="A lender wants to work with you!"
                    actionText={ null }
                    actionLink={ "#" }
                    heroModifiers="hero--arrow"
                    actionModifiers={ null }
                />
                <div><p>This is a test page. Transfer count is { transferCount }</p></div>
            </PageWrapper>
        );
    }
};

PlaceHolder.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default PlaceHolder;
